import { ActionTree } from 'vuex';
import { api } from 'boot/axios';
import { AxiosResponse } from 'axios';
import { CheckinPartnerOcrInterface } from 'src/interfaces/CheckinPartnerOcrInterface';
import { StateInterface } from '../index';
import { OcrDocumentStateInterface } from '.';

interface PayloadProcessOCR {
  imageBase64Front: string;
  imageBase64Back?: string;
  pmsPropertyId: number;
  token?: string;
  reservationId?: string;

}
const actions: ActionTree<OcrDocumentStateInterface, StateInterface> = {

  async processDocument(context, payload: PayloadProcessOCR) {
    return api
      .post('/ocr-document', payload)
      .then((response:AxiosResponse<CheckinPartnerOcrInterface>) => {
        context.commit('SET_DOCUMENT_DATA', response.data);
      });
  },

  async publicProcessDocument(context, payload: PayloadProcessOCR) {
    console.log('publicProcessDocument', payload);

    if (payload.token && payload.reservationId) {
      return api
        .post(`/ocr-document/${payload.reservationId}/precheckin-reservation/${payload.token}`, payload)
        .then((response:AxiosResponse<CheckinPartnerOcrInterface>) => {
          context.commit('SET_DOCUMENT_DATA', response.data);
        });
    }
    throw new Error('Token or reservationId is missing');
  },
};
export default actions;
