import { Module } from 'vuex';
import { FolioPrecheckinInterface } from 'src/interfaces/FolioPrecheckinInterface';
import { CheckinPartnerInterface } from 'src/interfaces/CheckinPartnerInterface';
import { StateInterface } from '../index';
import state from './state';
import actions from './actions';
import mutations from './mutations';

export interface PrecheckinStateInterface {
  folioPublicInfo: FolioPrecheckinInterface | null;
  existingCheckinPartner: CheckinPartnerInterface | null;
}

const foliosModule: Module<PrecheckinStateInterface, StateInterface> = {
  namespaced: true,
  actions,
  mutations,
  state,
};

export default foliosModule;
