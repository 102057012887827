import { PrecheckinStateInterface } from '.';

function state(): PrecheckinStateInterface {
  return {
    folioPublicInfo: null,
    existingCheckinPartner: null,
  };
}

export default state;
